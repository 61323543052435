import { RemoteCreateLead } from "../../usecases";
import { CreateLead } from "../../../domain/usecases";
import { makeApiLeadUrl, makeAxiosHttpClient } from "../http";
import { sirenaApiKey } from "../../constants/api-key";

export const makeRemoteCreateLead = (): CreateLead =>
  new RemoteCreateLead(
    makeApiLeadUrl("/lead/retail"),
    makeAxiosHttpClient(),
    sirenaApiKey
  );
