import { CarModel, ClientModel, LeadModel } from "../../domain/entitys";
import { LeadPayloadModel } from "../../domain/entitys/lead-payload.model";
import { AccessDeniedError, UnexpectedError } from "../../domain/errors";
import { CreateLead } from "../../domain/usecases";
import { HttpClient, HttpStatusCode } from "../entitys/http-client";

export class RemoteCreateLead implements CreateLead {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<LeadModel>,
    private readonly apiKey: string
  ) {}

  async create(
    client: ClientModel,
    car: CarModel,
    value?: string
  ): Promise<LeadModel> {
    const payLoad: LeadPayloadModel = {
      utmSource: "precificador 4boss",
      provider: "precificador 4boss/",
      firstName: client.name.split(" ")[0],
      lastName: client.name.split(" ")[1],
      phones: [client.phone],
      product: {
        detail: "venda",
        make: car.brand,
        model: car.model,
        ano: car.year,
        versao: car.version,
        quilometragem: car.km,
        marcaModelo: `${car.brand} ${car.model}`,
      },
    };
    if (value) {
      payLoad.product.valor = value;
    }
    const httpResponse = await this.httpClient.request({
      url: `${this.url}?api-key=${this.apiKey}`,
      method: "post",
      body: payLoad,
    });
    const remoteLead = httpResponse.body || undefined;
    switch (httpResponse.statusCode) {
      case 200:
        if (remoteLead?.id) return remoteLead;
        throw new UnexpectedError();
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}
